import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {GenRoleDefinition} from '../../../generated/serverModels/GenRoleDefinition';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(private auth: AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (next.data.requiredRoles === undefined) {
            throw new Error('requiredRoles property required in data section of route');
        }

        const roles: GenRoleDefinition[] = next.data.requiredRoles;
        const user = this.auth.authenticatedUser.value;

        if (user) {
            return user.hasAnyRole(roles);
        }

        return false;
    }
}
